import { Button, Typography } from '@material-ui/core';
import {
  OwnershipDetails,
  OwnershipDetailsRequest,
  userType,
} from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';
import React, { FC, useEffect, useState } from 'react';

import BlanketForm from '../blanketForm/BlanketForm';
import { BlanketForm as Form } from '../blanketForm/BlanketFormConstants';
import { Formik } from 'formik';
import authorityStyles from './OwnershipFormStyles';
import { checkFormVisibility, getDefaultValue } from './formUtils';

interface AuthorityFormProps {
  onSubmit: (data: OwnershipDetailsRequest) => void;
  onNext: () => void;
  ownershipDetails: OwnershipDetails;
  userType?: userType;
  formUrl: string;
  onBack: () => void;
  previewMode?: boolean;
}

const AuthorityForm: FC<AuthorityFormProps> = ({
  onSubmit,
  onNext,
  ownershipDetails,
  userType,
  formUrl,
  onBack,
  previewMode,
}) => {
  const [showSectionErrors, setShowSectionErrors] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      const msg = showAlert ? 'You have unsaved changes' : null;
      e.returnValue = msg;
      return msg;
    };
    if (showAlert) {
      window.addEventListener('beforeunload', listener);
    } else {
      window.removeEventListener('beforeunload', listener);
    }
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [showAlert]);

  if (!ownershipDetails) return null;
  const classes = authorityStyles();

  return (
    <Formik
      initialValues={{
        ...ownershipDetails,
        email: ownershipDetails.email || '',
        commissionSharing: getDefaultValue(
          ownershipDetails,
          JSON.parse(formUrl) as Form[],
          'commissionSharing',
          userType,
        ),
        otherDetails: { gstRegistered: ownershipDetails.gstRegistered },
      }}
      onSubmit={(data) => {
        userType === 'SecondaryOwner'
          ? onNext()
          : onSubmit({
              primaryOwner: data.primaryOwner,
              secondaryOwners: data.secondaryOwners?.filter((item) => Object.keys(item).length),
              displayName: data.displayName || '',
              email: data.email || '',
              fees: data.fees,
              howYouHear: data.howYouHear,
              financialDetails: data.financialDetails,
              contacts: data.contacts?.filter((item) => Object.keys(item).length),
              opportunityId: data.opportunityId,
              preferredTrades: data.preferredTrades,
              type: data.type,
              comment: data.comment?.message,
              authorityPeriod: data.authorityPeriod,
              rent: data.rent,
              bond: data.bond,
              urgentRepairs: data.urgentRepairs,
              services: data.services,
              marketingFees: data.marketingFees,
              commissionSharing: data.commissionSharing?.filter((item) => Object.keys(item).length),
              totalAdAndMarketingCosts: data.totalAdAndMarketingCosts,
              levelOfService: data.levelOfService,
              clientAcknowledgements: data.clientAcknowledgements,
              marketingExpenses: data.marketingExpenses,
              rebateStatements: data.rebateStatements,
              commissionSharingTerms: data.commissionSharingTerms,
              rebates: data.rebates?.filter((item) => Object.keys(item).length),
              guarantor: data.guarantor,
              acn: data.acn,
              abn: data.abn,
              corporationName: data.corporationName,
              trades: data.trades?.filter((item) => Object.keys(item).length),
              appointment: data.appointment,
              disclosureStatements: data.disclosureStatements,
              waterConsumptionCharges: data.waterConsumptionCharges,
              gstRegistered: data.otherDetails?.gstRegistered,
              authorizedDisbursement: data.authorizedDisbursement,
              contentInsuranceDetails: data.contentInsuranceDetails,
              publicLiabilityInsuranceDetails: data.publicLiabilityInsuranceDetails,
              additionalFees: data.additionalFees?.filter((item) => Object.keys(item).length),
              organizeLandlordInsurance: data.organizeLandlordInsurance,
              organizeLandlordInsuranceOption: data.organizeLandlordInsuranceOption,
              organizeLandlordInsuranceOptionOther: data.organizeLandlordInsuranceOptionOther,
              autoTriggerOwnersInstructions: data.autoTriggerOwnersInstructions,
              expensesReimbursement: data.expensesReimbursement,
              licenseeAuthorization: data.licenseeAuthorization,
              tenantWelcomePack: data.tenantWelcomePack,
            });
      }}
      enableReinitialize
    >
      {({ setFieldValue, values, submitForm, errors, dirty }) => {
        setShowAlert(dirty);
        const hasError = !!Object.keys(errors).length;
        const setFieldValue2 = (field: string, value: string) => {
          setFieldValue(field, value);
        };
        return (
          <>
            {(JSON.parse(formUrl) as Form[])
              .filter(
                (form) =>
                  userType &&
                  form.canView?.includes(userType) &&
                  checkFormVisibility(form, ownershipDetails.type),
              )
              .map((form) => {
                const readOnly =
                  !(userType && form.canEdit?.includes(userType)) ||
                  (ownershipDetails.status === 'SIGNED' && form.lockAfterSigning) ||
                  previewMode;
                return (
                  <BlanketForm
                    name={form.name}
                    heading={form.heading}
                    isCollapsible={form.isCollapsible}
                    fields={form.fields}
                    canBeMultiple={form.canBeMultiple}
                    countLabel={form.countLabel}
                    setFieldValue={setFieldValue2}
                    min={form.min}
                    values={values}
                    errors={errors}
                    showSectionErrors={showSectionErrors}
                    readonly={readOnly}
                    lockCounter={
                      ownershipDetails.status === 'SIGNED' &&
                      form.canBeMultiple &&
                      form.lockAfterSigning
                    }
                    userType={userType}
                    isSigned={ownershipDetails.status === 'SIGNED'}
                  />
                );
              })}
            {hasError && (
              <div className={classes.errorContainer}>
                <Typography variant="caption" color="error" align="right">
                  Please Enter all Required Fields
                </Typography>
              </div>
            )}
            <div className={classes.buttonContainer}>
              <Button size="small" color="secondary" className={classes.button} onClick={onBack}>
                Back
              </Button>
              <Button
                size="small"
                color="primary"
                className={classes.button}
                onClick={() => {
                  if (previewMode) {
                    onNext();
                  } else {
                    setShowSectionErrors(true);
                    submitForm();
                  }
                }}
              >
                {previewMode ? 'Next' : 'Submit & Next'}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AuthorityForm;
