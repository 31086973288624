import { Button, Typography } from '@material-ui/core';
import {
  Owner,
  OwnershipDetails,
  OwnershipDetailsRequest,
  userType,
} from '../../lib/Redux/Types/ownershipForm/OwnershipFormResponse.data';
import React, { FC, useEffect, useState } from 'react';

import BlanketForm from '../blanketForm/BlanketForm';
import { BlanketForm as Form } from '../blanketForm/BlanketFormConstants';
import { Formik } from 'formik';
import ownershipStyles from './OwnershipFormStyles';

interface OwnershipFormProps {
  onSubmit: (data: OwnershipDetailsRequest) => void;
  onSubmitSecondaryOwner: (owner: Owner) => void;
  ownershipDetails: OwnershipDetails;
  userType?: userType;
  ownerId?: number;
  formUrl: string;
  canUpdateOwnership?: boolean;
  previewMode?: boolean;
  onNext: () => void;
}

const OwnershipForm: FC<OwnershipFormProps> = ({
  onSubmit,
  onSubmitSecondaryOwner,
  ownershipDetails,
  userType,
  ownerId,
  formUrl,
  canUpdateOwnership,
  previewMode,
  onNext,
}) => {
  const [showSectionErrors, setShowSectionErrors] = useState(false);
  const [showAlert, setShowAlert] = useState(true);

  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      const msg = showAlert ? 'You have unsaved changes' : null;
      e.returnValue = msg;
      return msg;
    };
    if (showAlert) {
      window.addEventListener('beforeunload', listener);
    } else {
      window.removeEventListener('beforeunload', listener);
    }
    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [showAlert]);

  if (!ownershipDetails) return null;

  const classes = ownershipStyles();

  const getCurrentSecondaryOwner = (): Owner | null | undefined => {
    if (ownershipDetails && ownershipDetails.secondaryOwners) {
      return ownershipDetails.secondaryOwners.find((owner) => owner.id === ownerId);
    }
    return null;
  };

  const getOtherSecondaryOwners = (): Owner[] => {
    if (ownershipDetails && ownershipDetails.secondaryOwners) {
      return ownershipDetails.secondaryOwners.filter((owner) => owner.id !== ownerId);
    }
    return [];
  };

  const forms = (JSON.parse(formUrl) as Form[]).filter(
    (form) => userType && form.canView?.includes(userType) && !form.hideOnPrepare,
  );

  return (
    <Formik
      initialValues={{
        ...ownershipDetails,
        ...(userType === 'SecondaryOwner' && {
          ownerDetails: getCurrentSecondaryOwner(),
          secondaryOwners: getOtherSecondaryOwners(),
        }),
        otherDetails: { gstRegistered: ownershipDetails.gstRegistered },
        email: ownershipDetails.email || '',
      }}
      onSubmit={(data) => {
        if (userType === 'SecondaryOwner') {
          onSubmitSecondaryOwner(data.ownerDetails!);
        } else {
          onSubmit({
            primaryOwner: data.primaryOwner,
            secondaryOwners: data.secondaryOwners?.filter((item) => Object.keys(item).length),
            displayName: data.displayName || '',
            email: data.email || '',
            fees: data.fees,
            financialDetails: data.financialDetails,
            contacts: data.contacts?.filter((item) => Object.keys(item).length),
            opportunityId: data.opportunityId,
            preferredTrades: data.preferredTrades,
            type: data.type,
            comment: data.comment?.message,
            authorityPeriod: data.authorityPeriod,
            rent: data.rent,
            bond: data.bond,
            urgentRepairs: data.urgentRepairs,
            services: data.services,
            marketingFees: data.marketingFees,
            commissionSharing: data.commissionSharing?.filter((item) => Object.keys(item).length),
            totalAdAndMarketingCosts: data.totalAdAndMarketingCosts,
            levelOfService: data.levelOfService,
            clientAcknowledgements: data.clientAcknowledgements,
            marketingExpenses: data.marketingExpenses,
            rebateStatements: data.rebateStatements,
            commissionSharingTerms: data.commissionSharingTerms,
            rebates: data.rebates?.filter((item) => Object.keys(item).length),
            guarantor: data.guarantor,
            acn: data.acn,
            abn: data.abn,
            corporationName: data.corporationName,
            trades: data.trades?.filter((item) => Object.keys(item).length),
            appointment: data.appointment,
            disclosureStatements: data.disclosureStatements,
            waterConsumptionCharges: data.waterConsumptionCharges,
            gstRegistered: data.otherDetails?.gstRegistered,
            authorizedDisbursement: data.authorizedDisbursement,
            contentInsuranceDetails: data.contentInsuranceDetails,
            publicLiabilityInsuranceDetails: data.publicLiabilityInsuranceDetails,
            additionalFees: data.additionalFees?.filter((item) => Object.keys(item).length),
            organizeLandlordInsurance: data.organizeLandlordInsurance,
            organizeLandlordInsuranceOption: data.organizeLandlordInsuranceOption,
            organizeLandlordInsuranceOptionOther: data.organizeLandlordInsuranceOptionOther,
            autoTriggerOwnersInstructions: data.autoTriggerOwnersInstructions,
            expensesReimbursement: data.expensesReimbursement,
            licenseeAuthorization: data.licenseeAuthorization,
            tenantWelcomePack: data.tenantWelcomePack,
            howYouHear: data.howYouHear,
          });
        }
      }}
      enableReinitialize
    >
      {({ setFieldValue, values, submitForm, errors, dirty }) => {
        setShowAlert(dirty);
        const hasError = !!Object.keys(errors).length;
        const setFieldValue2 = (field: string, value: string) => {
          setFieldValue(field, value);
        };
        return (
          <>
            {forms.map((form) => {
              const readOnly =
                !(userType && form.canEdit?.includes(userType)) ||
                (form.checkIfUpdatable && !canUpdateOwnership) ||
                (ownershipDetails.status === 'Signed' && form.lockAfterSigning) ||
                previewMode;
              return (
                <BlanketForm
                  name={form.name}
                  heading={form.heading}
                  isCollapsible={form.isCollapsible}
                  fields={form.fields}
                  canBeMultiple={form.canBeMultiple}
                  countLabel={form.countLabel}
                  setFieldValue={setFieldValue2}
                  min={form.min}
                  values={values}
                  errors={errors}
                  showSectionErrors={showSectionErrors}
                  readonly={readOnly}
                  lockCounter={
                    ownershipDetails.status === 'Signed' &&
                    form.canBeMultiple &&
                    form.lockAfterSigning
                  }
                  userType={userType}
                />
              );
            })}
            {hasError && (
              <div className={classes.errorContainer}>
                <Typography variant="caption" color="error" align="right">
                  Please enter all required fields
                </Typography>
              </div>
            )}
            <div className={classes.buttonContainer}>
              <Button
                size="small"
                className={classes.button}
                color="primary"
                onClick={() => {
                  if (previewMode) {
                    onNext();
                  } else {
                    setShowSectionErrors(true);
                    submitForm();
                  }
                }}
              >
                {previewMode ? 'Next' : 'Submit & Next'}
              </Button>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default OwnershipForm;
